import { RasaContext } from 'context'
import { getEnvironmentSuffix } from 'generic/utility';
import * as React from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore';
import { newsletterWhitelistDomains, WhitelistDomains } from 'whitelist-domain/utils'
import { WidgetIframe } from './iframe'

interface WidgetTopArticlesProps {
  width?: string,
  height?: string,
  title?: string,
}

interface WidgetsTopArticlesState {
  communityId: string,
  communityGuid: string,
  isLoading: boolean,
  newsletterDomains: WhitelistDomains,
}
export class WidgetTopArticles extends React.Component<WidgetTopArticlesProps, WidgetsTopArticlesState> {
  public static contextType = RasaContext;
  private sharedStore: SharedStore
  constructor(props: any) {
    super(props)
    this.state = {
      communityId: null,
      communityGuid: null,
      isLoading: true,
      newsletterDomains: {},
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
    ]).then(([activeCommunity, person]) => {
      this.setState({
        communityId: activeCommunity.communityId,
        communityGuid: activeCommunity.data.community_guid,
      })

      newsletterWhitelistDomains((person.accountInfo || {}).account_guid, activeCommunity.communityId)
      .then((newsletterDomains: WhitelistDomains) => {
        this.setState({
          newsletterDomains,
          isLoading: false,
        })
      })
    })
  }
  public render() {
    return this.state.isLoading ? null :
      <div className="h-100">
        <div className="widget-title"><a href={this.topArticleUrl()} target="_blank">{this.props.title}</a></div>
        <WidgetIframe url={this.topArticleUrl(true)} width={this.props.width} height={this.props.height} />
      </div>
  }
  private topArticleUrl = (isIframe?: boolean) => {
    return `${this.pagesWebsite()}/articles/${this.state.communityGuid}${isIframe ? '?iframe=true' : ''}`
  }
  private pagesWebsite = (): string => {
    if (this.state.newsletterDomains.pages && this.state.newsletterDomains.pages.alternate_domain) {
      return `https://${this.state.newsletterDomains.pages.alternate_domain}`
    } else {
      return `https://pages${getEnvironmentSuffix()}.rasa.io`
    }
  }
}
