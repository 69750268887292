import { RasaContext } from 'context'
import * as React from 'react'
import { Col, Row } from 'reactstrap'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { COMMUNITY_NAME, USER_FIRST_NAME } from '../constants'
import { ComponentState, WidgetComponent } from '../types'
import * as Utils from '../utils'
import { WidgetComponents } from './constants'
import { WidgetItem } from './dashboardWidgetItem'
import './styles.scss'

export class WidgetsComponent extends React.Component<any, ComponentState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: any) {
    super(props)
    this.state = {
      activeCommunity: {},
      components: [],
      person: {},
    }
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
    ])
    .then(([activeCommunity, person]) => {
      Utils.getWidgetsList().then((widgets: WidgetComponent[]) => {
        this.setState({
          activeCommunity,
          components: widgets,
          person,
        })
      })
    })
  }

  public render() {
    return (
      <div className="dashboard-widget-wrapper">
          <Row>
            <Col>
            <div className="dashboard-title">Welcome, {this.state.person.firstName}</div>
            </Col>
          </Row>
          <Row>
            {this.state.components.map((component, rowIndex) => {
              return <Col md={component.colMd}>
                {this.getWidgetsRow(component, rowIndex)}
              </Col>
            })}
          </Row>
      </div>
    )
  }
  private getWidgetsRow = (item: WidgetComponent, rowIndex) => {
      return item.rows.components.map((component) => {
        return <Row key={rowIndex} className={item.rows.className || ''}>
                <Col key={component.component}>
                <WidgetItem className={component.component}
                  activeCommunity={this.state.activeCommunity}
                  person={this.state.person}
                  title={this.mergeTokens(component.title)}
                >
                  {React.createElement(WidgetComponents[component.component], component.props)}
                </WidgetItem>
          </Col>

    </Row>
    })
  }

  private mergeTokens(text) {
    text = text || ''
    return text.replace(COMMUNITY_NAME, this.state.activeCommunity.communityName)
              .replace(USER_FIRST_NAME, this.state.person.firstName)
  }
}
